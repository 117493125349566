@import '../utils/palette';

.rmc-form-element {
    $root: &;

    position: relative;
    min-width: 0;

    &--disabled {
        #{$root}__label {
            color: $borders-3;
        }
    }

    &__label {
        display: block;
        line-height: 1rem;
        margin-bottom: .55rem;
        letter-spacing: .5px;
        font-weight: 500;

        .rmc-checkbox & {
            display: inline;
            vertical-align: middle;
        }
    }

    &__optional {
        float: right;
        font-size: .75rem;
        line-height: 1rem;
    }

    &__help {
        display: block;
        font-size: .75rem;
        margin-top: .5rem;

        .rmc-field-error & {
            color: $red;
        }
    }

    &__control {
        position: relative;
    }
}
