@import '../utils/palette';
@import '../utils/variables';

html,
body {
    min-height: 100%;
}

body {
    background-color: $white;
    color: $dark-gray;
    font-family: 'Lato', Arial, sans-serif;
    font-size: $font-size;
    line-height: 1.5;
    letter-spacing: .5px;
    margin: 0;
    min-height: 100%;
    overflow-x: hidden;
    position: absolute;
    width: 100%;

    &.rmc-no-scroll {
        overflow: hidden;
    }
}

a {
    color: $primary-blue;
    text-decoration: underline;

    &:hover {
        color: $secondary-gold;
    }
}

.rmc {
    &-clickable {
        cursor: pointer;
        transition: $default-transition;

        &:hover {
            box-shadow: $shadow-small;
        }
    }

    &-confirmation-description {
        p,
        ul {
            margin-bottom: .75rem;
        }
    }
}


.slds-p-around--xlarge {
    padding: 2.7rem 3.3rem;
}

p {
    margin-bottom: .75rem;
}

img[width="1"][height="1"] {
    position: absolute;
    top: -1px;
}

.grecaptcha-badge {
    visibility: hidden;
}
