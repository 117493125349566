@import './palette';

$button-border-color: #a17e43;
$button-border-radius: .1875rem;
$button-color-bottom: #d1a559;
$button-color-hover: #e4ad4e;
$button-color-top: $orange;

$checkout-margin-s: 7.625rem;
$checkout-margin-m: 3.8125rem;
$checkout-margin-l: 0;
$checkout-screen-m: 37.5rem;

$default-border: 1px solid $borders-3;
$default-transition: all 300ms ease;

$card-border-radius: .25rem;
$container-max-width: 67rem;
$container-padding-horizontal: 1.5rem;
$font-size: 1rem;
$footer-top-min-height: 8.7rem;
$footer-bottom-height: 3.3rem;
$step-count: 7;

$sidebar-z-index: 9000;
$modal-z-index: 10000;

$shadow-small: 0 1px 3px 0 rgba(0,0,0,.15);
$shadow-medium: 0 2px 5px 0 rgba(0,0,0,.2);

$screen-m: 48rem;
$screen-l: 64rem;
$tile-screen-m: 48rem;
$tile-screen-l: 60rem;
