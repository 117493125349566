.rmc-backdrop {
    background-color: $white;
    bottom: 0;
    left: 0;
    opacity: .5;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $modal-z-index;
}
