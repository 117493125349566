.rmc-pricing-option {
    $option: &;

    align-items: baseline;
    display: flex;
    font-size: 1rem;
    padding: 1rem 0;
    text-transform: uppercase;

    &--headline {
        #{$option}__name {
            font-size: 1.1rem;
            color: $primary-blue;
        }
    }

    &--subitem {
        padding-top: 0;
        text-transform: none;

        #{$option}__name {
            margin-left: 1rem;
        }
    }

    &--footer {
        font-size: .75rem;
        padding: 0;
    }

    &__name {
        flex-grow: 1;
        
        &--strong {
            font-weight: 700;
        }
    }

    &__value {
        white-space: nowrap;
    }

    &__currency {
        font-size: .9em;
        margin-left: .5rem;
    }

    &__actions {
        position: relative;
    }
}
