@import '../utils/palette';
@import '../utils/variables';

$input-icon-padding: 2rem;
$input-icon-position: .75rem;
$input-icon-size: .875rem;
$input-padding-vertical: .25rem;

.rmc-input,
.rmc-textarea {
    $root: &;

    background-color: $white;
    border: 1px solid $primary-blue;
    box-shadow: inset 0 2px 3px 0 $borders-3;
    display: inline-block;
    line-height: 1.875rem;
    padding: $input-padding-vertical 1rem $input-padding-vertical .75rem;
    width: 100%;

    &::placeholder {
        color: $borders-3;
    }

    &:disabled {
        background-color: $borders-2;
        border-color: $borders-2;
        box-shadow: none;
    }

    &:not(:disabled) {
        &:active,
        &:focus {
            border-color: $primary-gold;
            box-shadow: 0 0 1px $primary-gold;
            outline: none;
    
            .rmc-field-error & {
                box-shadow: 0 0 1px $red;
            }
        }

        .rmc-field-error & {
            background-color: $pink;
            border-color: $red;
            color: $red;
        }

        .rmc-field-success & {
            background-color: $success-light;
            border-color: $success-color;
        }
    }
}

.rmc-input {
    height: 2.5rem;
}

.rmc-textarea {
    min-height: 2.5rem;
    resize: vertical;
}
