@import '../utils/palette';

$padding-horizontal: 1rem;

.rmc-sidebar {
    &__content {
        border-left: 1px solid $secondary-gold;
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__body {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
    }

    &__scrollable {
        bottom: 0;
        left: 0;
        overflow-y: auto;
        padding: 1.5rem $padding-horizontal;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__header {
        @include font-prata();

        border-bottom: 1px solid $borders-2;
        color: $primary-blue;
        font-size: 1.5rem;
        padding: 0 3rem .5rem 3rem;
        position: relative;
        text-align: center;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: 1.875rem;
        top: .5rem;
        z-index: 10;
    }

    &__footer {
        align-items: center;
        background: $borders-1;
        border-top: 1px solid $secondary-gold;
        display: flex;
        flex-shrink: 0;
        padding: .75rem $padding-horizontal;

        &-total {
            padding-right: 2rem;
            text-align: right;
        }
        &-actions,
        &-total {
            flex-grow: 1;
        }
        
        @media (min-width: $screen-m) {
            &-actions,
            &-total {
                flex-shrink: 0;
            }

            &-submit {
                flex-grow: 0;
                flex-shrink: 1;
            }
        }
    }
}
