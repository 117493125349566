@import '../utils/mixins';
@import '../utils/palette';

$price-width: 6rem;

.rmc-option {
    @include borders();

    &--hover {
        cursor: pointer;
    
        &:hover {
            background-color: $white-lilac;
        }
    }
    

    &__price-container {
        max-width: $price-width;
        min-width: $price-width;
    }
}
