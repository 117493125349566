.rmc-grid {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;

    &--center {
        align-content: center;
        align-items: center;
    }
}

.rmc-col {
    flex: 1 1 auto;
    padding-left: .5rem;
    padding-right: .5rem;
}

.rmc-grow {
    flex-grow: 1;
}
