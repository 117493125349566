@import '../utils/mixins';
@import '../utils/variables';

.rmc-heading {
    color: $primary-blue;
    text-transform: uppercase;

    &--s {
        font-size: .75rem;
    }

    &--m {
        font-size: 1rem;
    }

    &--l {
        font-size: 1.25rem;
    }

    &--xl {
        font-size: 1.75rem;
    }
}
