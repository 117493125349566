@import '../utils/palette';

$icon-size-s: .75rem;
$icon-size-m: 1.25rem;
$icon-size-l: 1.75rem;

.rmc-icon {
    &--s {
        height: $icon-size-s;
        width: $icon-size-s;
    }

    &--m {
        height: $icon-size-m;
        width: $icon-size-m;
    }

    &--l {
        height: $icon-size-l;
        width: $icon-size-l;
    }

    &--disabled {
        fill: $warm-gray-5;
    }

    &--primary-gold {
        fill: $primary-gold;
    }

    &--emerald {
        fill: $emerald;
    }

    &--fun-green {
        fill: $fun-green;
    }

    &--koromiko {
        fill: $koromiko;
    }

    &--white {
        fill: $white;
    }
}
