@import '../utils/variables';

.rmc-dropdown {
    background-color: $white;
    border: $default-border;
    border-top: none;
    border-radius: 0 0 $button-border-radius $button-border-radius;
    box-shadow: $shadow-small;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 10;
}
