@keyframes placeholder-animation {
    0% {
        background-position: 100% 55%;
    }

    50% {
        background-position: 0% 46%;
    }

    100% {
        background-position: 100% 55%;
    }
}

.rmc-placeholder {
    animation: placeholder-animation 8s ease infinite;
    background: linear-gradient(270deg, #ddd, #eee, #ddd);
    background-size: 200% 200%;
    border-radius: $button-border-radius;

    &--button {
        display: inline-block;
        height: 2.75rem;
        max-width: 10rem;
        width: 100%;
    }

    &--max-5 {
        max-width: 5rem;
    }

    &--max-10 {
        max-width: 10rem;
    }

    &--max-20 {
        max-width: 20rem;
    }

    &--max-40 {
        max-width: 40rem;
    }
}
