@import '../utils/mixins';
@import '../utils/palette';

.rmc-page-header {
    background-color: $background;

    &__content {
        @include container();

        align-items: center;
        display: flex;
    }

    &__title {
        padding: 1rem 1rem 1rem 0;
    }
}
