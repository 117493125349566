.rmc-tip {
    padding-left: 1.75rem;
    position: relative;

    &__icon {
        left: 0;
        position: absolute;
        top: 0;
    }
}
