/* primary colors */
$primary-blue: #0e2e50;
$primary-gold: #c49b54;
$dark-gray: #575757;

/* secondary colors */
$secondary-blue: #002559;
$secondary-gold: #dcae3e;

$tertiary-blue: #0488BD;
$border-blue: #9ad4d9;
$light-blue: #cce9ec;
$text-blue: #4d6c6f;

$borders-1: #f8f8f8;
$borders-2: #ebebeb;
$borders-3: #cacaca;

$success-color: #6FA494;
$success-light: #e6eeeb;

/* other colors */
$shadow: #ebebeb;
$background: #efefef;
$background-light: #f6f6f6;
$gray: #888888;
$orange: #f4b952;
$dark-blue: #0a2542;
$black: #000000;

/* alerts */
$pink: #faeaeb;
$red: #b71523;
$beige: #faebcc;
$brown: #8a6d3b;

/* SLDS colors */
$brand-light: #f4f6fe;
$emerald: #4bca81;
$fun-green: #028048;
$koromiko: #ffb75d;
$malibu: #5eb4ff;
$science-blue: #0070d2;
$warm-gray-3: #f3f2f2;
$warm-gray-5: #dddbda;
$white: #ffffff;
$white-lilac: #eef1f6;
