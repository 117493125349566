.rmc-select {
    appearance: none;
    background-color: $white;
    border: 1px solid $dark-gray;
    display: inline-block;
    height: calc(1.875rem + #{(1px * 2)} + 4px);
    line-height: 1.875rem;
    padding: 2px 1rem 2px .75rem;
    width: 100%;
    border-radius: 0;

    &-container {
        position: relative;
    }

    &-icon {
        pointer-events: none;
        position: absolute;
        right: .5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}
