.rmc-count-input {
    border: 3px solid $borders-3;
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    justify-content: space-between;
    align-items: center;

    &__label,
    &__count {
        padding: .5rem;
        font-size: .9rem;
        font-weight: 400;
        flex: 1;
    }

    &__count {
        text-align: center;
        max-width: 2.5rem;
    }

    &__increase,
    &__decrease {
        width: 2.5rem;
        text-align: center;
        text-decoration: none;
        color: $primary-gold;
        border-left: 3px solid $borders-3;
        height: 2.5rem;
        line-height: 2.4rem;

        &:hover {
            text-decoration: none;
        }
    }

    &__decrease {
        border-right: 3px solid $borders-3;
    }
}
