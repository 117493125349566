@import './palette';
@import './variables';

@mixin absolute-center {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin borders() {
    border: $default-border;
}

@mixin button-base() {
    @include button-reset();
    @include button-hover-transition();

    border: 1px solid transparent;
    border-radius: $button-border-radius;
    box-shadow: 0 2px 4px 0 $shadow;
    color: $primary-gold;
    line-height: 1.875rem;
    letter-spacing: 1px;
    max-width: 100%;
    min-width: 9rem;
    overflow-x: hidden;
    padding: .4rem 1.5rem;
    text-overflow: ellipsis;

    &:disabled {
        opacity: .65;
    }

    &:not(:disabled) {
        &:focus,
        &:hover {
            box-shadow: 0 1px 4px 0 $shadow;
            color: $primary-blue;
            text-decoration: none;

            .rmc-icon--button {
                fill: $primary-blue;
            }
        }
    }
}

@mixin button-hover-brand() {
    &:not(:disabled) {
        &:focus,
        &:hover {
            background-color: $primary-blue;
            border-color: $borders-3;
            color: $white;
        }
    }
}

@mixin button-hover-transition() {
    transition: $default-transition;
}

@mixin button-reset() {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
}

@mixin button-small() {
    font-size: .75rem;
    min-width: auto;
    padding: 0 .5rem;
}

@mixin container() {
    margin: 0 auto;
    padding-left: $container-padding-horizontal;
    padding-right: $container-padding-horizontal;

    &.narrow {
        max-width: $container-max-width;
    }
}

@mixin font-prata() {
    font-family: 'Prata', Arial, sans-serif;
}

@mixin selected() {
    background-color: $primary-gold;
    color: $white;
}

@mixin link-animation() {
    position: relative;

    &:not(:disabled):hover {
        &:after {
            width: 100%;
        }
    }

    &:after {
        background: none repeat scroll 0 0 transparent;
        left: 0px;
        bottom: -6px;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        background: $primary-gold;
        transition: width .3s ease 0s,left .3s ease 0s;
        width: 0;
    }
}

@mixin price-corner() {
    background-color: #f8f8f8;
    border-bottom: 1px solid #cacaca;
    border-left: 1px solid #cacaca;
    padding: .5rem .75rem 0.15rem;
    position: absolute;
    right: 0;
    top: 0;
    min-width: 8.5rem;

    .price-label {
        text-transform: uppercase;
        font-size: .5625rem;
        margin-bottom: .25rem;
    }
}

@mixin price-corner-selected() {
    position: absolute;
    background: #6FA494;
    color: white;
    padding: .5rem 1rem;
    right: -1rem;
    top: 1rem;
    letter-spacing: 0;

    @media (min-width: $screen-m) {
        right: -1.5rem;
    }

    @media (min-width: $screen-l) {
        right: -2rem;
    }

    &::after {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        background: linear-gradient(to top left, #fff 0%, #fff 50%, #547d70 50%, #547d70 100%);
        position: absolute;
        bottom: -1rem;
        right: 0;

        @media (min-width: $screen-m) {
            width: 1.5rem;
        }

        @media (min-width: $screen-l) {
            width: 2rem;
        }
    }
}
