@import '../utils/palette';
@import '../utils/variables';

$checkbox-size: 1.5rem;

.rmc-checkbox {
    $root: &;

    display: block;
    padding-left: 2rem;
    position: relative;

    &__input {
        display: none;
    }

    &__faux {
        border: 1px solid $primary-blue;
        cursor: pointer;
        display: inline-block;
        height: $checkbox-size;
        left: 0;
        position: absolute;
        width: $checkbox-size;
    
        &:before {
            background-image: url('/images/check-icon.svg');
            content: '';
            display: none;
            height: 1.0625rem;
            left: .125rem;
            position: absolute;
            top: .125rem;
            width: 1.125rem;
        }

        .rmc-field-error & {
            background-color: $pink;
            border-color: $red;
        }

        .rmc-field-success & {
            background-color: $success-light;
            border-color: $success-color;
        }
    
        #{$root}__input:checked ~ & {
            &:before {
                display: inline-block;
            }
        }
    
        #{$root}__input:disabled ~ & {
            background-color: $background;
            cursor: default;
            opacity: .5;
        }
    }
}
