/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
html,
body {
  min-height: 100%;
}

body {
  background-color: #ffffff;
  color: #575757;
  font-family: "Lato", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}
body.rmc-no-scroll {
  overflow: hidden;
}

a {
  color: #0e2e50;
  text-decoration: underline;
}
a:hover {
  color: #dcae3e;
}

.rmc-clickable {
  cursor: pointer;
  transition: all 300ms ease;
}
.rmc-clickable:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}
.rmc-confirmation-description p,
.rmc-confirmation-description ul {
  margin-bottom: 0.75rem;
}

.slds-p-around--xlarge {
  padding: 2.7rem 3.3rem;
}

p {
  margin-bottom: 0.75rem;
}

img[width="1"][height="1"] {
  position: absolute;
  top: -1px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.rmc-backdrop {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  transition: all 300ms ease;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  box-shadow: 0 2px 4px 0 #ebebeb;
  color: #c49b54;
  line-height: 1.875rem;
  letter-spacing: 1px;
  max-width: 100%;
  min-width: 9rem;
  overflow-x: hidden;
  padding: 0.4rem 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rmc-button:disabled {
  opacity: 0.65;
}
.rmc-button:not(:disabled):focus, .rmc-button:not(:disabled):hover {
  box-shadow: 0 1px 4px 0 #ebebeb;
  color: #0e2e50;
  text-decoration: none;
}
.rmc-button:not(:disabled):focus .rmc-icon--button, .rmc-button:not(:disabled):hover .rmc-icon--button {
  fill: #0e2e50;
}
.rmc-button--neutral {
  border: 1px solid #cacaca;
  background-color: #ffffff;
  border-color: #0e2e50;
  color: #0e2e50;
}
.rmc-button--neutral:not(:disabled):focus, .rmc-button--neutral:not(:disabled):hover {
  border-color: #dcae3e;
  background-color: #dcae3e;
  color: white;
}
.rmc-button--gray {
  background-color: #888888;
  border: 1px solid #888888;
  color: #ffffff;
}
.rmc-button--gray:not(:disabled):focus, .rmc-button--gray:not(:disabled):hover {
  background-color: #0e2e50;
  border-color: #cacaca;
  color: #ffffff;
}
.rmc-button--brand {
  background-color: #dcae3e;
  background-image: linear-gradient(to bottom, #f4b952 0%, #d1a559 100%);
  border: 1px solid #a17e43;
  color: #ffffff;
  z-index: 1;
}
.rmc-button--brand:before {
  transition: all 300ms ease;
  background-color: #e4ad4e;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 100%;
  top: 0;
  z-index: -1;
}
.rmc-button--brand:disabled {
  background-color: #ebebeb;
  background-image: none;
  border-color: transparent;
  color: #cacaca;
  opacity: 1;
}
.rmc-button--brand:disabled:before {
  content: none;
}
.rmc-button--brand:not(:disabled):focus, .rmc-button--brand:not(:disabled):hover {
  color: #ffffff;
}
.rmc-button--brand:not(:disabled):focus:before, .rmc-button--brand:not(:disabled):hover:before {
  right: 0;
}
.rmc-button--success {
  background-color: #6FA494;
  color: white;
}
.rmc-button--success:not(:disabled):focus, .rmc-button--success:not(:disabled):hover {
  color: white;
}
.rmc-button--simple, .rmc-button--simple:not(:disabled):focus, .rmc-button--simple:not(:disabled):hover {
  box-shadow: none;
}
.rmc-button .rmc-icon--button {
  transition: all 300ms ease;
  fill: #c49b54;
  vertical-align: middle;
}
.rmc-button .icon-checkmark {
  margin-right: 0.5rem;
}

.rmc-button-group .rmc-button {
  border-radius: 0;
  border-right: none;
}
.rmc-button-group .rmc-button:first-child {
  border-radius: 0.1875rem 0 0 0.1875rem;
}
.rmc-button-group .rmc-button:last-child {
  border: 1px solid #cacaca;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-card {
  border: 1px solid #cacaca;
  background-color: #ffffff;
  position: relative;
}
@media (min-width: 48rem) {
  .rmc-card--thumbnail {
    min-height: 15rem;
    padding-left: 15rem;
  }
}
@media (min-width: 60rem) {
  .rmc-card--thumbnail {
    min-height: 20rem;
    padding-left: 20rem;
  }
}
.rmc-card--primary {
  border-color: #c49b54;
  padding: 1.5rem;
}
@media (min-width: 64rem) {
  .rmc-card--primary {
    padding: 3rem;
  }
}
.rmc-card__thumbnail {
  border-radius: 0;
  height: 15rem;
}
@media (min-width: 48rem) {
  .rmc-card__thumbnail {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 15rem;
  }
}
@media (min-width: 60rem) {
  .rmc-card__thumbnail {
    width: 20rem;
  }
}
.rmc-card__thumbnail-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.rmc-card__price {
  background-color: #f8f8f8;
  border-bottom: 1px solid #cacaca;
  border-left: 1px solid #cacaca;
  padding: 0.5rem 0.75rem 0.15rem;
  position: absolute;
  right: 0;
  top: 0;
  min-width: 8.5rem;
}
.rmc-card__price .price-label {
  text-transform: uppercase;
  font-size: 0.5625rem;
  margin-bottom: 0.25rem;
}
.rmc-card__price--selected {
  position: absolute;
  background: #6FA494;
  color: white;
  padding: 0.5rem 1rem;
  right: -1rem;
  top: 1rem;
  letter-spacing: 0;
}
@media (min-width: 48rem) {
  .rmc-card__price--selected {
    right: -1.5rem;
  }
}
@media (min-width: 64rem) {
  .rmc-card__price--selected {
    right: -2rem;
  }
}
.rmc-card__price--selected::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  background: linear-gradient(to top left, #fff 0%, #fff 50%, #547d70 50%, #547d70 100%);
  position: absolute;
  bottom: -1rem;
  right: 0;
}
@media (min-width: 48rem) {
  .rmc-card__price--selected::after {
    width: 1.5rem;
  }
}
@media (min-width: 64rem) {
  .rmc-card__price--selected::after {
    width: 2rem;
  }
}
.rmc-card__price--sidebar {
  padding: 0.25rem 0.5rem;
  right: 0;
}
.rmc-card__price--sidebar::after {
  display: none;
}
.rmc-card__info {
  padding: 2rem;
  position: relative;
}
@media (min-width: 48rem) {
  .rmc-card__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2.5rem;
  }
}
.rmc-card__title {
  font-family: "Prata", Arial, sans-serif;
  color: #0e2e50;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  letter-spacing: 2px;
  width: 100%;
}
@media (min-width: 48rem) {
  .rmc-card__title {
    font-size: 1.5rem;
    padding-right: 6.5rem;
  }
}
@media (min-width: 64rem) {
  .rmc-card__title {
    font-size: 1.75rem;
  }
}
.rmc-card__description {
  margin-bottom: 2rem;
  max-width: 32.5rem;
  width: 100%;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-checkbox {
  display: block;
  padding-left: 2rem;
  position: relative;
}
.rmc-checkbox__input {
  display: none;
}
.rmc-checkbox__faux {
  border: 1px solid #0e2e50;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  width: 1.5rem;
}
.rmc-checkbox__faux:before {
  background-image: url("/images/check-icon.svg");
  content: "";
  display: none;
  height: 1.0625rem;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  width: 1.125rem;
}
.rmc-field-error .rmc-checkbox__faux {
  background-color: #faeaeb;
  border-color: #b71523;
}
.rmc-field-success .rmc-checkbox__faux {
  background-color: #e6eeeb;
  border-color: #6FA494;
}
.rmc-checkbox__input:checked ~ .rmc-checkbox__faux:before {
  display: inline-block;
}
.rmc-checkbox__input:disabled ~ .rmc-checkbox__faux {
  background-color: #efefef;
  cursor: default;
  opacity: 0.5;
}

.rmc-conditions p ~ p {
  margin-top: 1rem;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-container {
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.rmc-container.narrow {
  max-width: 67rem;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-dropdown {
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-top: none;
  border-radius: 0 0 0.1875rem 0.1875rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 10;
}

.rmc-form {
  max-width: 22rem;
  width: 100%;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-form-element {
  position: relative;
  min-width: 0;
}
.rmc-form-element--disabled .rmc-form-element__label {
  color: #cacaca;
}
.rmc-form-element__label {
  display: block;
  line-height: 1rem;
  margin-bottom: 0.55rem;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.rmc-checkbox .rmc-form-element__label {
  display: inline;
  vertical-align: middle;
}
.rmc-form-element__optional {
  float: right;
  font-size: 0.75rem;
  line-height: 1rem;
}
.rmc-form-element__help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.rmc-field-error .rmc-form-element__help {
  color: #b71523;
}
.rmc-form-element__control {
  position: relative;
}

.rmc-grid {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.rmc-grid--center {
  align-content: center;
  align-items: center;
}

.rmc-col {
  flex: 1 1 auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.rmc-grow {
  flex-grow: 1;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-heading {
  color: #0e2e50;
  text-transform: uppercase;
}
.rmc-heading--s {
  font-size: 0.75rem;
}
.rmc-heading--m {
  font-size: 1rem;
}
.rmc-heading--l {
  font-size: 1.25rem;
}
.rmc-heading--xl {
  font-size: 1.75rem;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-help {
  text-align: center;
}
.rmc-help__agent, .rmc-help__agent-photo {
  border-radius: 50%;
  display: inline-block;
}
.rmc-help__agent {
  border: 1px solid #c49b54;
  font-size: 0;
  margin-bottom: 1.5rem;
}
.rmc-help__agent--s {
  padding: 0.375rem;
}
.rmc-help__agent--l {
  padding: 0.75rem;
}
.rmc-help__agent-photo {
  background-image: url(/images/image-expert.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rmc-help__agent-photo--s {
  height: 5rem;
  width: 5rem;
}
.rmc-help__agent-photo--l {
  height: 11.5rem;
  width: 11.5rem;
}
.rmc-help__info {
  margin-bottom: 1.5rem;
}
.rmc-help__chat-button {
  background-color: #ffffff;
  color: #0e2e50;
  margin-bottom: 1.5rem;
}
.rmc-help__call-us {
  color: #0e2e50;
  display: block;
  font-weight: 700;
  text-decoration: none;
}
.rmc-help__call-us:hover {
  text-decoration: underline;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-icon--s {
  height: 0.75rem;
  width: 0.75rem;
}
.rmc-icon--m {
  height: 1.25rem;
  width: 1.25rem;
}
.rmc-icon--l {
  height: 1.75rem;
  width: 1.75rem;
}
.rmc-icon--disabled {
  fill: #dddbda;
}
.rmc-icon--primary-gold {
  fill: #c49b54;
}
.rmc-icon--emerald {
  fill: #4bca81;
}
.rmc-icon--fun-green {
  fill: #028048;
}
.rmc-icon--koromiko {
  fill: #ffb75d;
}
.rmc-icon--white {
  fill: #ffffff;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-input,
.rmc-textarea {
  background-color: #ffffff;
  border: 1px solid #0e2e50;
  box-shadow: inset 0 2px 3px 0 #cacaca;
  display: inline-block;
  line-height: 1.875rem;
  padding: 0.25rem 1rem 0.25rem 0.75rem;
  width: 100%;
}
.rmc-input::placeholder,
.rmc-textarea::placeholder {
  color: #cacaca;
}
.rmc-input:disabled,
.rmc-textarea:disabled {
  background-color: #ebebeb;
  border-color: #ebebeb;
  box-shadow: none;
}
.rmc-input:not(:disabled):active, .rmc-input:not(:disabled):focus,
.rmc-textarea:not(:disabled):active,
.rmc-textarea:not(:disabled):focus {
  border-color: #c49b54;
  box-shadow: 0 0 1px #c49b54;
  outline: none;
}
.rmc-field-error .rmc-input:not(:disabled):active, .rmc-field-error .rmc-input:not(:disabled):focus,
.rmc-field-error .rmc-textarea:not(:disabled):active,
.rmc-field-error .rmc-textarea:not(:disabled):focus {
  box-shadow: 0 0 1px #b71523;
}
.rmc-field-error .rmc-input:not(:disabled),
.rmc-field-error .rmc-textarea:not(:disabled) {
  background-color: #faeaeb;
  border-color: #b71523;
  color: #b71523;
}
.rmc-field-success .rmc-input:not(:disabled),
.rmc-field-success .rmc-textarea:not(:disabled) {
  background-color: #e6eeeb;
  border-color: #6FA494;
}

.rmc-input {
  height: 2.5rem;
}

.rmc-textarea {
  min-height: 2.5rem;
  resize: vertical;
}

.rmc-count-input {
  border: 3px solid #cacaca;
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: space-between;
  align-items: center;
}
.rmc-count-input__label, .rmc-count-input__count {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  flex: 1;
}
.rmc-count-input__count {
  text-align: center;
  max-width: 2.5rem;
}
.rmc-count-input__increase, .rmc-count-input__decrease {
  width: 2.5rem;
  text-align: center;
  text-decoration: none;
  color: #c49b54;
  border-left: 3px solid #cacaca;
  height: 2.5rem;
  line-height: 2.4rem;
}
.rmc-count-input__increase:hover, .rmc-count-input__decrease:hover {
  text-decoration: none;
}
.rmc-count-input__decrease {
  border-right: 3px solid #cacaca;
}

.rmc-modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10001;
}
.rmc-modal__container {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 2rem;
  padding: 3rem 0;
  position: relative;
}
@media (min-width: 48rem) {
  .rmc-modal__container {
    margin: 0 auto;
    width: 44rem;
  }
}
.rmc-modal__content {
  background-color: #ffffff;
  border: 1px solid #c49b54;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
}
@media (min-width: 48rem) {
  .rmc-modal__content {
    padding: 2rem 3rem;
  }
}
.rmc-modal__close {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}
.rmc-modal__header {
  font-family: "Prata", Arial, sans-serif;
  color: #0e2e50;
  font-size: 1.5rem;
  text-align: center;
}
.rmc-modal__body--message {
  text-align: center;
}
.rmc-modal__message-text {
  margin: 1rem 0;
}
.rmc-modal__message-action {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-option {
  border: 1px solid #cacaca;
}
.rmc-option--hover {
  cursor: pointer;
}
.rmc-option--hover:hover {
  background-color: #eef1f6;
}
.rmc-option__price-container {
  max-width: 6rem;
  min-width: 6rem;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-page-header {
  background-color: #efefef;
}
.rmc-page-header__content {
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  align-items: center;
  display: flex;
}
.rmc-page-header__content.narrow {
  max-width: 67rem;
}
.rmc-page-header__title {
  padding: 1rem 1rem 1rem 0;
}

@keyframes placeholder-animation {
  0% {
    background-position: 100% 55%;
  }
  50% {
    background-position: 0% 46%;
  }
  100% {
    background-position: 100% 55%;
  }
}
.rmc-placeholder {
  animation: placeholder-animation 8s ease infinite;
  background: linear-gradient(270deg, #ddd, #eee, #ddd);
  background-size: 200% 200%;
  border-radius: 0.1875rem;
}
.rmc-placeholder--button {
  display: inline-block;
  height: 2.75rem;
  max-width: 10rem;
  width: 100%;
}
.rmc-placeholder--max-5 {
  max-width: 5rem;
}
.rmc-placeholder--max-10 {
  max-width: 10rem;
}
.rmc-placeholder--max-20 {
  max-width: 20rem;
}
.rmc-placeholder--max-40 {
  max-width: 40rem;
}

.rmc-price {
  font-family: "Prata", Arial, sans-serif;
}
.rmc-price__value {
  font-size: 1rem;
  margin-right: 0.25rem;
}
.rmc-price__currency {
  font-size: 0.625rem;
}

.rmc-pricing-option {
  align-items: baseline;
  display: flex;
  font-size: 1rem;
  padding: 1rem 0;
  text-transform: uppercase;
}
.rmc-pricing-option--headline .rmc-pricing-option__name {
  font-size: 1.1rem;
  color: #0e2e50;
}
.rmc-pricing-option--subitem {
  padding-top: 0;
  text-transform: none;
}
.rmc-pricing-option--subitem .rmc-pricing-option__name {
  margin-left: 1rem;
}
.rmc-pricing-option--footer {
  font-size: 0.75rem;
  padding: 0;
}
.rmc-pricing-option__name {
  flex-grow: 1;
}
.rmc-pricing-option__name--strong {
  font-weight: 700;
}
.rmc-pricing-option__value {
  white-space: nowrap;
}
.rmc-pricing-option__currency {
  font-size: 0.9em;
  margin-left: 0.5rem;
}
.rmc-pricing-option__actions {
  position: relative;
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-radio {
  display: inline-block;
}
.rmc-radio__label {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  transition: all 300ms ease;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  box-shadow: 0 2px 4px 0 #ebebeb;
  color: #c49b54;
  line-height: 1.875rem;
  letter-spacing: 1px;
  max-width: 100%;
  min-width: 9rem;
  overflow-x: hidden;
  padding: 0.4rem 1.5rem;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  min-width: auto;
  padding: 0 0.5rem;
  border: 1px solid #cacaca;
}
.rmc-radio__label:disabled {
  opacity: 0.65;
}
.rmc-radio__label:not(:disabled):focus, .rmc-radio__label:not(:disabled):hover {
  box-shadow: 0 1px 4px 0 #ebebeb;
  color: #0e2e50;
  text-decoration: none;
}
.rmc-radio__label:not(:disabled):focus .rmc-icon--button, .rmc-radio__label:not(:disabled):hover .rmc-icon--button {
  fill: #0e2e50;
}
.rmc-radio input[type=radio] {
  display: none;
}
input[type=radio]:checked ~ .rmc-radio__label {
  background-color: #c49b54;
  color: #ffffff;
}

.rmc-radio-group .rmc-radio__label {
  border-radius: 0;
  border-right: none;
}
.rmc-radio-group .rmc-radio:first-child .rmc-radio__label {
  border-radius: 0.1875rem 0 0 0.1875rem;
}
.rmc-radio-group .rmc-radio:last-child .rmc-radio__label {
  border: 1px solid #cacaca;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.rmc-select {
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #575757;
  display: inline-block;
  height: calc(1.875rem + 2px + 4px);
  line-height: 1.875rem;
  padding: 2px 1rem 2px 0.75rem;
  width: 100%;
  border-radius: 0;
}
.rmc-select-container {
  position: relative;
}
.rmc-select-icon {
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-sidebar__content {
  border-left: 1px solid #dcae3e;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.rmc-sidebar__body {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}
.rmc-sidebar__scrollable {
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 1.5rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
}
.rmc-sidebar__header {
  font-family: "Prata", Arial, sans-serif;
  border-bottom: 1px solid #ebebeb;
  color: #0e2e50;
  font-size: 1.5rem;
  padding: 0 3rem 0.5rem 3rem;
  position: relative;
  text-align: center;
  margin-left: -1rem;
  margin-right: -1rem;
}
.rmc-sidebar__close {
  cursor: pointer;
  position: absolute;
  right: 1.875rem;
  top: 0.5rem;
  z-index: 10;
}
.rmc-sidebar__footer {
  align-items: center;
  background: #f8f8f8;
  border-top: 1px solid #dcae3e;
  display: flex;
  flex-shrink: 0;
  padding: 0.75rem 1rem;
}
.rmc-sidebar__footer-total {
  padding-right: 2rem;
  text-align: right;
}
.rmc-sidebar__footer-actions, .rmc-sidebar__footer-total {
  flex-grow: 1;
}
@media (min-width: 48rem) {
  .rmc-sidebar__footer-actions, .rmc-sidebar__footer-total {
    flex-shrink: 0;
  }
  .rmc-sidebar__footer-submit {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-step:nth-child(1) .rmc-step-index:before {
  content: "1";
}

.rmc-step:nth-child(2) .rmc-step-index:before {
  content: "2";
}

.rmc-step:nth-child(3) .rmc-step-index:before {
  content: "3";
}

.rmc-step:nth-child(4) .rmc-step-index:before {
  content: "4";
}

.rmc-step:nth-child(5) .rmc-step-index:before {
  content: "5";
}

.rmc-step:nth-child(6) .rmc-step-index:before {
  content: "6";
}

.rmc-step:nth-child(7) .rmc-step-index:before {
  content: "7";
}

/* primary colors */
/* secondary colors */
/* other colors */
/* alerts */
/* SLDS colors */
.rmc-text--brand {
  color: #c49b54;
}
.rmc-text--success {
  color: #4bca81;
}

.rmc-tip {
  padding-left: 1.75rem;
  position: relative;
}
.rmc-tip__icon {
  left: 0;
  position: absolute;
  top: 0;
}

.rmc-tour-heading {
  padding-bottom: 0.25rem;
  padding-top: 2.75rem;
}