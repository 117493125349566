@import '../utils/mixins';
@import '../utils/palette';
@import '../utils/variables';

$thumbnail-min-size-m: 15rem;
$thumbnail-min-size-l: 20rem;

.rmc-card {
    @include borders();

    background-color: $white;
    position: relative;

    &--thumbnail {
        @media (min-width: $tile-screen-m) {
            min-height: $thumbnail-min-size-m;
            padding-left: $thumbnail-min-size-m;
        }

        @media (min-width: $tile-screen-l) {
            min-height: $thumbnail-min-size-l;
            padding-left: $thumbnail-min-size-l;
        }
    }

    &--primary {
        border-color: $primary-gold;
        padding: 1.5rem;
    
        @media (min-width: $screen-l) {
            padding: 3rem;
        }
    }

    &__thumbnail {
        border-radius: 0;
        height: 15rem;
    
        @media (min-width: $tile-screen-m) {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: $thumbnail-min-size-m;
        }
    
        @media (min-width: $tile-screen-l) {
            width: $thumbnail-min-size-l;
        }
    }
    
    &__thumbnail-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__price {
        @include price-corner();
    }
    
    &__price--selected {
        @include price-corner-selected();
    }

    &__price--sidebar {
        padding: .25rem .5rem;
        right: 0;

        &::after {
            display: none;
        }
    }

    &__info {
        padding: 2rem;
        position: relative;

        @media (min-width: $screen-m) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 2.5rem;
        }
    }

    &__title {
        @include font-prata();

        color: $primary-blue;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        letter-spacing: 2px;
        width: 100%;

        @media (min-width: $screen-m) {
            font-size: 1.5rem;
            padding-right: 6.5rem;
        }

        @media (min-width: $screen-l) {
            font-size: 1.75rem;
        }
    }

    &__description {
        margin-bottom: 2rem;
        max-width: 32.5rem;
        width: 100%;
    }
}
