.rmc-modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ($modal-z-index + 1);

    &__container {
        border-radius: .25rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 0 2rem;
        padding: 3rem 0;
        position: relative;

        @media (min-width: $screen-m) {
            margin: 0 auto;
            width: 44rem;
        }
    }

    &__content {
        background-color: $white;
        border: 1px solid $primary-gold;
        overflow-y: auto;
        padding: 2rem;
        position: relative;
    
        @media (min-width: $screen-m) {
            padding: 2rem 3rem;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }

    &__header {
        @include font-prata();

        color: $primary-blue;
        font-size: 1.5rem;
        text-align: center;
    }

    &__body {
        &--message {
            text-align: center;
        }
    }

    &__message-text {
        margin: 1rem 0;
    }

    &__message-action {
        margin-left: .5rem;
        margin-right: .5rem;
        margin-top: .5rem;
    }
}
