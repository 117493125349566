.rmc-price {
    @include font-prata();

    &__value {
        font-size: 1rem;
        margin-right: .25rem;
    }

    &__currency {
        font-size: .625rem;
    }
}
