@import '../utils/palette';

$agent-photo-size--s: 5rem;
$agent-photo-size--l: 11.5rem;

.rmc-help {
    text-align: center;

    &__agent,
    &__agent-photo {
        border-radius: 50%;
        display: inline-block;
    }

    &__agent {
        border: 1px solid $primary-gold;
        font-size: 0;
        margin-bottom: 1.5rem;

        &--s {
            padding: .375rem;
        }

        &--l {
            padding: .75rem;
        }
    }

    &__agent-photo {
        background-image: url(/images/image-expert.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &--s {
            height: $agent-photo-size--s;
            width: $agent-photo-size--s;
        }

        &--l {
            height: $agent-photo-size--l;
            width: $agent-photo-size--l;
        }
    }

    &__info {
        margin-bottom: 1.5rem;
    }

    &__chat-button {
        background-color: $white;
        color: $primary-blue;
        margin-bottom: 1.5rem;
    }

    &__call-us {
        color: $primary-blue;
        display: block;
        font-weight: 700;
        text-decoration: none;
    
        &:hover {
            text-decoration: underline;
        }
    }
}
