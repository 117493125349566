@import '../utils/mixins';
@import '../utils/palette';
@import '../utils/variables';

.rmc-button {
    @include button-base();

    white-space: nowrap;

    &--neutral {
        @include borders();

        background-color: $white;
        border-color: $primary-blue;
        color: $primary-blue;

        &:not(:disabled) {
            &:focus,
            &:hover {
                border-color: $secondary-gold;
                background-color: $secondary-gold;
                color: white;
            }
        }
    }

    &--gray {
        @include button-hover-brand();

        background-color: $gray;
        border: 1px solid $gray;
        color: $white;
    }

    &--brand {
        background-color: $secondary-gold;
        background-image: linear-gradient(to bottom, $button-color-top 0%, $button-color-bottom 100%);
        border: 1px solid $button-border-color;
        color: $white;
        z-index: 1;

        &:before {
            @include button-hover-transition();

            background-color: $button-color-hover;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 100%;
            top: 0;
            z-index: -1;
        }

        &:disabled {
            background-color: $borders-2;
            background-image: none;
            border-color: transparent;
            color: $borders-3;
            opacity: 1;

            &:before {
                content: none;
            }
        }

        &:not(:disabled) {
            &:focus,
            &:hover {
                color: $white;

                &:before {
                    right: 0;
                }
            }
        }
    }

    &--success {
        background-color: $success-color;
        color: white;

        &:not(:disabled):focus,
        &:not(:disabled):hover {
            color: white;
        }
    }

    &--simple, &--simple:not(:disabled):focus, &--simple:not(:disabled):hover {
        box-shadow: none;
    }

    .rmc-icon--button {
        @include button-hover-transition();

        fill: $primary-gold;
        vertical-align: middle;
    }

    .icon-checkmark {
        margin-right: .5rem;
    }
}

.rmc-button-group {
    .rmc-button {
        border-radius: 0;
        border-right: none;

        &:first-child {
            border-radius: $button-border-radius 0 0 $button-border-radius;
        }

        &:last-child {
            @include borders();

            border-radius: 0 $button-border-radius $button-border-radius 0;
        }
    }
}
