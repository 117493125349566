@import '../utils/mixins';

.rmc-radio {
    display: inline-block;

    &__label {
        @include button-base();
        @include button-small();
        @include borders();
    }

    input[type=radio] {
        display: none;
    }

    input[type=radio]:checked ~ &__label {
        @include selected();
    }
}

.rmc-radio-group {
    .rmc-radio {
        &__label {
            border-radius: 0;
            border-right: none;
        }

        &:first-child .rmc-radio__label {
            border-radius: $button-border-radius 0 0 $button-border-radius;
        }

        &:last-child .rmc-radio__label {
            @include borders();

            border-radius: 0 $button-border-radius $button-border-radius 0;
        }
    }
}
