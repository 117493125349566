@import '../utils/palette';

.rmc-text {
    &--brand {
        color: $primary-gold;
    }

    &--success {
        color: $emerald;
    }
}
